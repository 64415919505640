.order-list {
    width: 100%;

    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    margin-top: 2rem;

    display: none;

    @include md {
        display: flex;
    }

    .list-item {
        position: relative;
        margin: 0.5rem;
        min-width: 15rem;
        width: 15%;
        width: calc(20% - 1rem);
        background-color: white;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px;

        .order {
            padding: 0.5rem 1rem;

            &.disabled {
                opacity: 0.5;
            }
        }

        .order-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 0.5rem;


            .order-info-text {
                margin: 0;
            }
            .order-ready-time {
                font-weight: bold;
            }
            .order-name {
                font-weight: bold;
            }
            .order-time-counter {
                font-weight: 100;
                font-size: 14px;
                margin-left: 6px;
                color: #9d9d9d;
            }
        }

        .order-receipt-number-container {
            .order-receipt-number {
                margin: 0;
                color: #9d9d9d;
                font-size: 14px;
            }
        }

        .order-body {
            margin-top: 1rem;
            padding-bottom: 4rem;

            .order-element-container {
                display: flex;
                align-items: flex-start;
                margin: 1rem 0;
                overflow: hidden;
            }
        }
    }
}

.run-button-container {
    display: flex;
    justify-content: flex-end;

    .run-button {
        background-color: $crunchorder-blue;
        color: white;

        &:disabled {
            background-color: #c1c1c1;
            border-color: #c1c1c1;
            color: #686868;
        }
    }
}