.summary-list {
    width: 100%;

    flex-wrap: wrap;
    justify-content: flex-start;

    margin-top: 2rem;

    display: none;

    @include md {
        display: flex;
    }

    .summary-element {
        position: relative;
        margin: 0.5rem 1%;
        padding: 1.5rem 2rem;
        width: 49%;
        width: calc(48% - 4rem);
        background-color: white;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px;

        .element {
            .element-child-container {
                margin-left: 10px;

                .element-child-child-container {

                    &.disabled {
                        opacity: 1;
                    }
                    
                    .checkbox {
                        display: none;
                    }
                }
            }
        }
    }
}