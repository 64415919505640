.checkbox {
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"] + div.checkmark-container {
        display: inline-block;
        position: relative;
        top: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin: 0;
        vertical-align: middle;
        background: white left top no-repeat;
        border: 1px solid #dbdbdb;
    }
    
    input[type="checkbox"] + div.checkmark-container {
        margin-right: 4px;
    }
}

// Checkmark animation
:root {
    --checkmark-circle-color: #bddda2;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: var(--checkmark-circle-color);
    fill: none;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 0 auto;
    box-shadow: inset 0px 0px 0px var(--checkmark-circle-color);
    animation: fill .2s ease-in-out .2s forwards, scale .2s ease-in-out .3s both;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes scale {
    0%, 100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px var(--checkmark-circle-color);
    }
}